import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { LoadingSpinner } from './components/spinners/index';
import './styles/globals.css';

const Routes = React.lazy(() => import('./routes'));

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 5,
      retryDelay: 1000,
    },
    queries: {
      retry: 5,
      retryDelay: 1000,
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <React.Suspense
          fallback={<LoadingSpinner position="absolute" size="large" />}
        >
          <BrowserRouter>
            <Routes />
            <Toaster
              position="top-center"
              reverseOrder={false}
              containerStyle={{
                zIndex: 9999999,
              }}
              toastOptions={{
                error: {
                  style: {
                    border: '2px solid #c53030',
                  },
                },
              }}
            />
          </BrowserRouter>
        </React.Suspense>
      </QueryClientProvider>
    </>
  );
}

export default App;
