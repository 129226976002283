import { Stitches } from 'src/styles/stitches';

const rotate = Stitches.keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const LoadingSpinnerWrapper = Stitches.styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > div': {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    animation: `${rotate} 1s linear infinite`,
    borderRightColor: 'transparent',
    borderStyle: 'solid',
  },

  variants: {
    size: {
      small: {
        '& > div': {
          width: '$iconLarge',
          height: '$iconLarge',
          borderWidth: '1.5px',
        },
      },
      medium: {
        '& > div': {
          width: '$2',
          height: '$2',
          borderWidth: '2px',
        },
      },
      large: {
        '& > div': {
          width: '$3',
          height: '$3',
          borderWidth: '3px',
        },
      },
      extraSmall: {
        '& > div': {
          width: 15,
          height: 15,
          borderWidth: '1px',
        },
      },
    },
    position: {
      absolute: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        zIndex: '$4',
      },
      static: {
        position: 'initial',
        width: 'fit-content',
        height: 'fit-content',
      },
    },
    velocity: {
      slow: {
        '& > div': {
          animationDuration: '1s',
        },
      },
      fast: {
        '& > div': {
          animationDuration: '0.6s',
        },
      },
    },
    color: {
      primary: {
        '& > div': {
          borderTopColor: '$primary',
          borderLeftColor: '$primary',
          borderBottomColor: '$primary',
        },
      },
      white: {
        '& > div': {
          borderTopColor: '$white',
          borderLeftColor: '$white',
          borderBottomColor: '$white',
        },
      },
    },
  },

  defaultVariants: {
    position: 'initial',
    velocity: 'fast',
    color: 'primary',
  },
});
