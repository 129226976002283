export const ptBRMeritocracyPageTranslation = {
  meritocracy: {
    actions: {
      download: 'Exportar simulação para excel',
      only_simulated: 'Apenas simulados',
    },
    simulation: {
      title: 'Meritocracia',
      description: 'Selecione ou crie um novo cenário para fazer simulações',
    },
    recommendation: {
      title: 'Recomendação de Meritocracia',
    },
  },
};
