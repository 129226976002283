export const ptBRMeritocracyParamatersPageTranslation = {
  meritocracyParameters: {
    title: 'Parametrizações',
    description:
      'Decida os pesos e critérios que definirão como o sistema recomenda alterações na remuneração dos seus colaboradores.',
    tabs: {
      performanceCriteriaParameter: {
        title: 'Critérios de Análise',
        description:
          'Decida os pesos e critérios que definirão como o sistema recomenda alterações na remuneração dos seus colaboradores.',
        content: {
          weights: {
            title: 'Pesos',
            tables: {
              weights: {
                headers: {
                  crietria: 'Critério',
                },
                bottom: {
                  performance_competencies: 'Competências',
                  performance_individual: 'Apuração de metas individuais',
                  performance_final: 'Performance Final',
                  sum: 'Soma',
                },
              },
            },
            actions: {
              save: 'Salvar pesos',
            },
          },
          criterias: {
            title: 'Critérios',
            tables: {
              common: {
                headers: {
                  ref: 'Ref.',
                  performance: 'Desempenho',
                },
                bottom: {
                  max: 'Max',
                  target: 'Alvo',
                  min: 'Min',
                },
              },
              performance_competencies: {
                title: 'Competências',
              },
              performance_individual: {
                title: 'Apuração de metas Individuais',
              },
              performance_final: {
                title: 'Performance final',
              },
              performance_potencial: {
                title: 'Potencial',
              },
            },
            actions: {
              save: 'Salvar critérios',
            },
          },
        },
      },
      politicalParameter: {
        title: 'Políticas de meritocracia',
        content: {
          political: {
            tables: {
              political: {
                title: 'Políticas',
                headers: {
                  type_salary_adjustment: 'Tipo de reajuste salarial',
                  min: '% Mínimo',
                  max: '% Máximo',
                },
                bottom: {
                  salary_increase: 'Aumento salarial',
                  promotion: 'Promoção',
                },
                actions: {
                  save: 'Salvar',
                },
              },
            },
          },
        },
      },
      automaticSimulation: {
        title: 'Modelo de simulação automática',
        content: {
          automatic_simulation: {
            options: {
              budget_distribution: {
                title: 'Distribuição do orçamento',
                values: {
                  integral: 'Distribuição integral até zerar o orçamento',
                  all: 'Garantir que todos os elegíveis recebam um montante, aplicando um redutor se necessário',
                },
              },
              segmentation: {
                title: 'Segmentação',
                values: {
                  employees: 'Ocupantes',
                  level: 'Nível',
                },
              },
            },
            actions: {
              save: 'Salvar',
            },
          },
        },
      },
    },
  },
};
