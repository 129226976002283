export const translationEN = {
  common: {
    filters: {
      table_salary: 'Table salary',
      unit: 'Unit',
      director: 'Board director',
      area: 'Area',
      grade_level: 'Grade level',
      contract_type: 'Contract type',
      employee_code: 'Employee code',
    },
    actions: {
      search: 'search',
      apply: 'apply',
      edit: 'edit',
      create: 'create',
      save: 'save',
      delete: 'delete',
      remove: 'remove',
      clear: 'clear',
      download: 'download',
    },
  },
  pages: {
    meritocracy: {
      actions: {
        download: 'Export simulation to excel',
        only_simulated: 'Only simulated',
      },
      simulation: {
        title: 'Meritocracy Simulation',
        description: 'Select or create a new scenario for simulations',
      },
      recommendation: {
        title: 'Meritocracy Recommendation',
      },
    },
  },
};
