import { LoadingSpinnerWrapper } from './styles';

type Props = typeof LoadingSpinnerWrapper.defaultProps;

export function LoadingSpinner({ ...props }: Props) {
  return (
    <LoadingSpinnerWrapper {...props}>
      <div />
    </LoadingSpinnerWrapper>
  );
}
