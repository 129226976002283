import { translationEN } from './en/translation';
import { translationPTBR } from './pt-br/translation';

export const translationResource = {
  'pt-BR': {
    translation: translationPTBR,
  },
  'en-US': {
    translation: translationEN,
  },
};
